<template>
    <div class="content-wrapper">
        <!-- 就餐服务-就餐订单-->
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <expand-filter
                    :formData="formData"
                    :closeWidth="900"
                    marginBottom="10px"
                    borderRadius="4px"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                    @datePickerBlur="datePickerBlur"
                ></expand-filter>
                <div class="table-paging">
                    <table-data
                        :config="table_config"
                        :tableData="table_data"
                        v-loading="loadingTable"
                        backgroundHeader="#FAFBFC"
                        :headerStyle="{height: '50px'}"
                        :rowStyle="{height: '50px'}"
                        ref="table"
                        id="table"
                    >
                    </table-data>
                    <pagination
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="getList()"
                    />
                </div>
            </div>
        </div>
        <error
            v-else
            :errorShow="errorShow"
        ></error>
    </div>
</template>

<script>
import {
    FilterData,
    Loading,
    MTreeSelect,
    Pagination,
} from "common-local";
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter";
import TableData from "@/components/scrollWrapper/Sub/TableData";
import {CustodialServicesModel} from "@/models/RepastServices.js";
import {mapState} from "vuex";
import RecordsList from '@/libs/pageListOptimal.js';
import onResize from "@/mixins/onResize"
import exportReactive from '@/libs/exportReactive.js';
import { downloadFile , formatTreeData } from "@/libs/utils";


export default {
    name: "RepastOrderView",
    components: {
        FilterData,
        Loading,
        TableData,
        Pagination,
        MTreeSelect,
        ExpandFilter,
    },
    mixins: [onResize],
    data() {
        return {
            loadingShow: false,
            errorShow: false,
            loadingTable: false,
            // 班级多选
            value: [],
            menuList: [],
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                projectId: '',  
                schoolId: '',  
                classIdList: [],
                studentName: '',
                startTime: "",
                endTime: "",
                projectName: "",
                schoolName: "",
            },
            // 头部筛选
            formData: {
                btnFormType: true,
                data: [
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "项目名称",
                        key: "projectId",
                        filterable: true,
                        list: []
                    },
                    {
                        type: "cascader",
                        label: "",
                        value: [],
                        placeholder: "班级",
                        key: "classIdList",
                        list: [],
                        cascaderProps: {
                            label: "name",
                            multiple: true,
                            value: "id",
                            emitPath: false
                        }
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "学生姓名",
                        key: "studentName",
                    },
                    {
                        type: "datePick",
                        label: "",
                        key: "dateArr",
                        value: [],
                        dateType: "daterange",
                        format: "yyyy-MM-dd HH:mm",
                        valueFormat: "yyyy-MM-dd HH:mm",
                        startPlaceholder: "支付开始时间",
                        endPlaceholder: "支付结束时间",
                        defaultTime: ["00:00:00", "23:59:59"],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["repastOrder:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                    },
                    {
                        type: "enquiry",
                        text: "导出",
                        fn: "export",
                        loading: false,
                        auth: ['repastOrder:export']
                    },
                ],
            },
            table_config: {
                thead: [
                    {
                        label: "项目名称",
                        prop: "projectName",
                        align: "left",
                        labelWidth: "160px",
                    },
                    {
                        label: "年级",
                        prop: "gradeName",
                        align: "center",
                    },
                    {
                        label: "班级",
                        prop: "className",
                        align: "center",
                    },
                    {
                        label: "姓名",
                        prop: "studentName",
                        align: "center",
                    },
                    {
                        label: "唯一号",
                        prop: "onlyCode",
                        align: "center",
                    },
                    {
                        label: "天数",
                        prop: "days",
                        align: "center",
                    },
                    {
                        label: "单价",
                        prop: "unitPrice",
                        align: "center",
                    },
                    {
                        label: "合计",
                        prop: "amount",
                        align: "center",
                    },
                    {
                        label: "支付时间",
                        prop: "payTime",
                        align: "center",
                        align: "center",
                        labelWidth: "160px",
                    },
                    {
                        label: "商户订单号",
                        prop: "outTradeNo",
                        align: "center",
                        labelWidth: "200px",
                    },
                    {
                        label: "支付宝流水号",
                        prop: "bankNo",
                        align: "center",
                        labelWidth: "200px",
                    },
                ],
                check: false,
                height: "",
            },
            table_data: [],
            total: 0,
            titleOptions: [],
        };
    },
    created() {
        this.init();
    },
    computed: {
        ...mapState({
            permissions: (state) => state.permissions,
            schoolId: (state) => state.schoolId,
        }),
    },
    methods: {
        /**
         * @Description: 初始化数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-07 16:55:10
         */
         async init() {
            this.listQuery.schoolId = this.schoolId;
            await this.initAssignment();
        },
        async initAssignment() {
            await this.getSchoolRepastList();  // 项目列表
            this.getOrgList();  // 班级列表
            this.setDefaultTitle();
            this.getList();
        },
        setDefaultTitle() {
            console.log('默认项目名称');
            
            this.formData.data.forEach((item) => {
                if (item.key == 'projectId') {
                    item.value = this.titleOptions[0]?.value;
                    console.log('item.value',item.value);
                    
                    if (item.value) {
                        this.listQuery[item.key] = this.titleOptions[0]?.value
                    } else {
                        this.listQuery[item.key] = ''
                    }
                }
            });
            console.log('this.listQuery1111111',this.listQuery);
            
        },
        /**
         * @Description: 获取组织架构班级接口
         * @Author: 闫乔
         * @Date: 2024-11-06 16:38:36
         */       
         getOrgList() {
            this._fet("/school/schoolOrgan/listByConditionNoAuth", {
                schoolId: this.schoolId,
            }).then((res) => {
                if (res.data.code === '200') {
                    const index = this.formData.data.findIndex((i) => i.type === 'cascader')
                    const list = res.data.data.list;
                    const list2 = list.filter((it) => it.organType != 1);
                    console.log('list2list2',list2);
                    
                    this.formData.data[index].list =
                        formatTreeData(list2, "id", "parentOrg")
                    
                } else {
                    this.$message.error(res.data.msg)
                }
            });
        },
         /**
         * @Description: 获取项目列表
         * @Author: 闫乔
         * @Date: 2025-02-28 14:34:22
         */        
         async  getSchoolRepastList() {
            await this._fet("/school/schoolRepast/listByCondition", {
                schoolId: this.schoolId,
            }).then((res) => {
                this.handleRes(res, () => {
                    console.log('res.data.data',res.data.data);
                    const index = this.formData.data.findIndex((i) => i.key === 'projectId')
                    this.titleOptions = res.data.data.map(i => ({ label: i.title, value: i.id }));
                    console.log('项目列表',this.titleOptions);
                    
                    this.formData.data[index].list = this.titleOptions;
                    this.formData.data[index].value = this.titleOptions[0]?.value
                    this.listQuery.projectId = this.titleOptions[0]?.value
                    console.log('this.listQuery2222',this.listQuery);
                    
                });
            });
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        /**
         * @Description: 获取表格数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-09 11:11:20
         */
        getList(isPaging) {
            let data = {}
            if (isPaging) {
                this.listQuery.pageNum = 1
            }
            // this.formData.data.forEach(item => {
            //     data[item.key] = item.value
            // })
            console.log('this.listQuery---------------',this.listQuery);
            
            this.loadingTable = true
            this._fet("/school/schoolRepastOrder/list", this.listQuery).then(res => {
                if (res.data.code === '200') {
                    this.total = res.data.data.totalCount || 0
                    this.table_data = res.data.data.list || [];
                    
                    //赋值--存储滚动条高度
                    this.$nextTick(() => {
                        let recordsList =  new RecordsList()
                        if(recordsList.get(this.$route.name)){
                            this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                        }
                        //移除--存储滚动条高度
                        recordsList.remove(this.$route.name)
                    })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
                this.loadingTable = false
            })
        },
        /**
         * @Description: 日期选择
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-12 15:10:16
         */
        datePickerBlur() {
            let dateArr = this.formData.data.find((item) => {
                return item.key == "dateArr";
            });
            if (dateArr.value) {
                let [startTime, endTime] = dateArr.value ? dateArr.value : ["", ""];
                this.listQuery.startTime = startTime + ':00';
                this.listQuery.endTime = endTime + ':59';
            } else {
                this.listQuery.startTime = "";
                this.listQuery.endTime = "";
            }
        },
        /**
         * @Description: 查询/重置/添加
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-09 11:10:40
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                        if (item.key == "classIdList" || item.key == "dateArr") {
                            if (!item.value) {
                                this.listQuery[item.key] = [];
                            }
                        }
                    });
                    this.getList(1);
                    break;
                case "reset": //重置
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = ''
                        item.value = ''
                    })
                    this.listQuery.dateArr = []
                    this.listQuery.classIdList = []
                    this.listQuery.projectId = this.titleOptions[0]?.value
                    this.setDefaultTitle();
                    this.getList(1)
                    break;
                case "export":
                    exportReactive(this.formData.btnList, 'export', true)
                    this.handleExport()
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 导出
         * @Author: 闫乔
         * @Date: 2024-11-06 16:37:27
         */        
        handleExport() {
            let index = this.formData.btnList.findIndex((i) => i.fn === 'export')
            this.formData.btnList[index].loading = true
            downloadFile(
                {
                    url: "/school/schoolRepastOrder/export",
                    method: "post",
                    form: this.listQuery,
                },
                () => {
                    this.formData.btnList[index].loading = false
                    exportReactive(this.formData.btnList, 'export', false)
                    this.$message.success('导出成功')
                },
                () => {
                    this.formData.btnList[index].loading = false
                },
            );
        },
        /**
         * @Description: 获取日期
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-10 09:45:51
         */
        getNewDate() {
            if (this.addRuleForm.length > 0) {
                this.addRuleForm.depositStartDate = this.addRuleForm[0];
                this.addRuleForm.depositEndDate = this.addRuleForm[1];
            }
        },
        filterFn(data) {
            return data.filter((i) => i.organType === "4");
        },
    },
};
</script>

<style lang="scss" scoped>
.content-wrapper{
    padding-right: 10px;
}
.meetings-my-list {
    .content-wrapper .el-date-editor {
        width: 340px !important;
    }

    .table-paging {
        overflow: hidden;
        border-radius: 6px;
        background-color: #ffffff;
    }
}

/deep/ .el-menu-vertical-demo {
    border: solid 1px #e6e6e6 !important;
}
/deep/ .el-submenu__title {
    height: 30px !important;
    line-height: 30px;
    font-size: 14px;
    color: #303133;
    padding: 0;
}
/deep/.el-menu-item-group {
    background: #f7f7f7;
}
/deep/.el-menu-item-group__title {
    padding: 0;
}
/deep/ .el-submenu .el-menu-item {
    height: 20px !important;
    line-height: 20px;
    font-size: 13px;
    color: #303133;
    padding: 0;
}
.open-style {
    min-height: 200px;
    padding: 0 !important;
    margin: 0;
    overflow: auto;
    cursor: default !important;
}
.stafftree {
    /deep/ .el-tree-node {
        .el-checkbox__input.is-disabled {
            display: none;
        }
    }
}
</style>
